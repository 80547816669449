import LazyIframe from "components/common/iframe/lazyIframe";
import LayoutStep from "components/common/layout/layoutStep";
import { titleTrainingDays } from "data/config";
import Link from "gatsby-link";
import React from "react";

import SEO from "../../components/SEO/SEO";

const Step5 = () => {
  return (
    <LayoutStep>
      <SEO
        title={`Common modules | ${titleTrainingDays}`}
        description={"Elementary training workshop, job training and risk prevention & training in workplace "}
        canonical={"training-days/step-5-0/"}
      />
      <div className="container step">
        <p className={"h1"} id={"title"}>
          {titleTrainingDays} - Step 5.0{" "}
        </p>
        <h1 className={"slideLeft"}>Common modules</h1>
        <section className={"videoCart"}>
          <main className={"slideLeft s05"}>
            <div className={"video--bloque--a"}></div>
            <LazyIframe src={"9zYF4ZU6KiU"} title={"Common modules"} />
          </main>
          <aside className={"slideLeft s1"}>
            <div className="stepNavigation">
              <Link to="/training-days/step-4/#top" className={"stepNavigation__button--back "}>
                {" "}
                <span className={"stepNavigation__button--back--right"}> </span> Back{" "}
              </Link>
              <Link to="/training-days/step-5-1/#top" className={"stepNavigation__button--next"}>
                {" "}
                <span className={"stepNavigation__button--next--left"}> </span> Next{" "}
              </Link>
            </div>
            <h3>ELEMENTARY TRAINING WORKSHOP</h3>
            <p>
              Basic instrumental learning and personal and social development is taught to the students in both courses.
              They are common to the three initial qualification programmes, regardless the professional field.
            </p>
          </aside>
        </section>
        <section className={"slideLeft s11"}>
          <h3>JOB TRAINING AND RISK PREVENTION </h3>
          <p>
            This module is taught in both courses in every program. It includes training about basic office software,
            occupational health and safety and the working world.
          </p>
          <h4>OCCUPATIONAL RISK PREVENTION DEGREE </h4>
          <p>
            {" "}
            With this module the students are given a certificate of basic level in occupational risk prevention, needed
            to carry out the Training in Workplace
          </p>
          <a className={"pdf"} href={"/downloads/Anexo_PRL_PCI_autorrellenable.pdf"} rel="noreferrer" target={"_blank"}>
            Attached
          </a>{" "}
          <br />
          <a
            className={"pdf"}
            href={"/downloads/JOB-TRAINING-AND-RISK-PREVENTION.pdf"}
            rel="noreferrer"
            target={"_blank"}
          >
            RISK PREVENTION
          </a>
          <h3> TRAINING IN WORKPLACE</h3>
          <p>... first we will see the professional families</p>
        </section>
      </div>
    </LayoutStep>
  );
};

export default Step5;
